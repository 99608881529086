.overFlow-hidden{
    --overflow:hidden !important;
}
.home_close{
    ion-header {
        ion-toolbar{
        .toolbar-title 
        {
            ion-img {
            display: none !important;
        }
    }
    }
}
}

.wrapper{
    --background:var( --ion-color-secondary);
    &::-webkit-scrollbar {
        display: none;
      }
    
}

.row{
    padding:0 17px ;
  .column
    {
        background-color: #ffffff;
        padding: 47px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        margin: 20px 0 0 0;
        ion-img{
            width: 80px;
            height: 80px;
            margin: auto ;
        }
        p{
            margin:7px 0 0 0;
            text-align: center;
            font-size: 24px;
            line-height: 29px;
            font-weight: 500;
            color: var(--ion-color-primary);
        }
    }
    .column2
    {
        background-color: #ffffff;
        padding: 47px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        margin: 17px 0 0 0;
        ion-img{
            width: 80px;
            height: 80px;
            margin: auto ;
        }
        p{
            margin: 7px 0 0 0;
            text-align: center;
            font-size: 24px;
            line-height: 29px;
            font-weight: 500;
            // padding: 20px;
            color: var(--ion-color-primary);
        }
    }
}
/* List componenet style*/
.product-detailList{
     padding: 15px 16px;
     position: relative;
    .list-grid{
        padding: 18px 0 23px;
        .list-row{
            align-items: center;
            .button-column{
                text-align: end;
                padding: 0;
            }
        }
    }
    .list-data{
        padding-bottom: 10rem;
        .list-detail{
            margin: 10px 4px 24px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .num-id{
                display: flex;
                justify-content: space-around;
                align-items: baseline;
                h5{
                    margin: 0 11px 0 0;
                    color: var(--ion-color-primary);
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 19px;
                }
            }
            ion-img{
               width: 14px;
            }
        }
    }
 
    .record-input{
        position: absolute;
        left: 0;
        right:0;
        bottom: 0;
        padding: 30px 10px;
}
    }

    // @media screen and (max-height: 670px){
    //     .product-detailList{
    //         .record-input{
    //             position: absolute;
    //         left: 0;
    //         right: 0;
    //         bottom: -90px;
    //         padding:16px;
    //     }
    //     }
    // }
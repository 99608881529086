ion-header{
    ion-toolbar{
        --background:var(--ion-color-primary);
        .toolbar-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:0 17px 0 11px;
            p{
                color: white;
                font-size: 19px;
                font-weight: 500;
                font-family: 'Montserrat';
            }
            ion-img{
             width: 16px;
            }
        }
    }
}
.heading-portion{
    padding: 33px 16px 76px 16px;
}
h3{
    font-size: 24px !important;
    font-family: 'Montserrat';
    font-weight: 600 !important;
    margin: 0 !important;
    font-style: normal;
    color:var(--ion-color-primary) ;
}
h4{
    font-size: 24px !important;
    font-family: 'Montserrat';
    font-weight: 500 !important;
    margin: 0 !important;
    font-style: normal;
    color:var(--ion-color-primary) ;
}
.detail_with_image{
    ion-img{
        width: 70%;
        margin: 0 auto 46px;
    }

}
.id-input-div{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    padding: 10px 16px 0;
}
.record-items{
    border: 1px solid var(--ion-color-primary) !important;
    color: var(--ion-color-primary);
    margin:0 0 10px 0 !important;
    font-weight: 500 !important;
    font-family: 'Montserrat' !important;
    font-size: 16px !important;
    padding-right: 0 !important;
    border-radius: 4px !important;
    --inner-padding-end:0;
    }
.p-0{
    padding: 0!important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.mb-15{
    margin-bottom: 15px !important;
}
.arrow-forward-icon{
    color: var(--ion-color-primary);
    font-size: 21px;
    padding: 0 15px 0 0;
}
.reset-button{
    margin: 0px !important;
    --background: var(--ion-color-tertiary) !important;
    text-transform: capitalize;
    --border-radius: 4px;
    font-size: 16px;
    font-family: 'Montserrat';
}
.confirm-button{
    width: 100%;
    height: 51px;
    margin: 0;
    --border-radius:4px;
    font-size: 16px;
    text-transform: capitalize;
    font-family: 'Montserrat';
    font-weight: 700;
}
ion-backdrop{
    background-color: var(--ion-color-primary);
}
.my-custom-class{
    background: #2a525682;
}
.alerimage-style{
        width: 80px;
        height: 80px;
        margin: auto;
        display: flex;
        justify-content: center;
}
.alert-head.sc-ion-alert-ios{
    padding:0 !important ;
}
.alert-head.sc-ion-alert-md{
    padding:0 !important ;
}
.alert-message.sc-ion-alert-ios {
    max-height: 240px;
    color: var(--ion-color-primary);
    font-family: 'Montserrat';
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    img{
       
        width: 80px;
        height: 80px;
        margin: auto;
        justify-content: center;
        display: flex;
    }
    p{
        margin: 10px 0;
        text-align: center;
        font-size: 18px;
    }
    h6{
        margin: 10 0;
        text-align: center;
        color: var(--ion-color-tertiary);
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }
}
.alert-message.sc-ion-alert-md {
    max-height: 240px;
    color: var(--ion-color-primary);
    font-family: 'Montserrat';
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    img{
        width: 80px;
        height: 80px;
        margin: auto;
        justify-content: center;
        display: flex;
    }
    p{
        margin: 10px 0;
        text-align: center;
        font-size: 19px;
    }
    h6{
        margin: 10px 0;
        text-align: center;
        font-size: 23px;
        font-weight: 500;
        line-height: 29px;
        color: var(--ion-color-tertiary) !important;
    }
}
.alert-button.sc-ion-alert-ios:last-child {
    background: var(--ion-color-primary);
    color: white;
    width: 100%;
    font-weight: 700;
    margin: 0 !important;
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 4px !important;
}
.alert-button.sc-ion-alert-md:last-child {
    background:var(--ion-color-primary);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    border-radius: 4px;
    width: 100%;
    margin: 0;
}
.secondary{
  width: 50% !important;
  margin: 0 5px !important;
}
.alert-button-group.sc-ion-alert-md {
    flex-flow: row;
    justify-content: center;
    padding:0 15px 15px;
}
.alert-button-inner.sc-ion-alert-md {
    justify-content: center;
}
.alert-button-group.sc-ion-alert-ios {
    flex-flow: row;
    justify-content: center;
    padding: 0 15px 15px;
}
.alert-message.sc-ion-alert-ios{
    padding: 15px 21px !important;
    text-align: start;
    font-style: normal;
    text-transform: none;
    font-weight: 500;
    font-size: 19px;
}
.alert-message.sc-ion-alert-md{
    padding: 15px 21px !important;
    text-align: start;
    font-style: normal;
    text-transform: none;
    font-weight: 500;
    font-size: 19px;
}
.alert-button-inner.sc-ion-alert-ios {
    justify-content: center;
}
.alert-wrapper.sc-ion-alert-md {
    border-radius: 13px !important;
}
.alert-button.sc-ion-alert-ios {
    color: white;
    background:var(--ion-color-tertiary);
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    padding: 0 !important;
    margin: 0 5px !important;
    border-radius:4px;
}
.alert-button.sc-ion-alert-md {
    color: white;
    background:var(--ion-color-tertiary);
    text-transform: capitalize;
    padding: 17px 62px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 4px;
    font-family: 'Montserrat';
}
.wrapper{
    .ps-15{
        padding-left: 15px;
        padding-top: 20px;
    }
.grid-section{
    padding: 63px 20px 0 20px;
.ion-radio-row{
    display: flex;
    margin-bottom: 13px;
    .ion-radio-col{
        display: flex;
        justify-content: start;
        padding: 20px 0;
        .custom-radio{
            width: 30px;
            height: 30px;
            background-color: white;
            border-radius: 50%;
           
        }
      
        .custom-label{
            display: flex;
            align-items: center;
            margin-left: 10px;
           font-size: 24px;
           font-weight: 700;
           
        }
    }
    // .ion-radio-col:nth-child(2){
    //     display: flex;
    //     justify-content: center;
    // }
    // .ion-radio-col:nth-child(3){
    //     display: flex;
    //     justify-content: end;
    // }
  
}
}
}

/**ionic custom tags **/
ion-radio {
    --color: white !important;
  }
  ion-backdrop{
    --backdrop-opacity:0.9 !important;
  }
  ion-item.item-has-focus > .arrow-forward-icon{
    background: var(--ion-color-primary) !important;
    color: white !important;
    padding: 10px 10px 11px 10px !important;
    border-radius: 0 4px 4px 0 !important;
    font-size: 23px !important ;
  }